.spinner-container {
  height: 100vh;
  flex-direction: column;
  display: flex;
}

.spinner {
  width: 40px;
  height: 40px;
  margin: auto;
  position: relative;
}

.cube1, .cube2 {
  width: 15px;
  height: 15px;
  background-color: #333;
  animation: 1.8s ease-in-out infinite sk-cubemove;
  position: absolute;
  top: 0;
  left: 0;
}

.cube2 {
  animation-delay: -.9s;
}

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(42px)rotate(-90deg)scale(.5);
  }

  50% {
    -webkit-transform: translateX(42px)translateY(42px)rotate(-180deg);
  }

  75% {
    -webkit-transform: translateX(0)translateY(42px)rotate(-270deg)scale(.5);
  }

  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(42px)rotate(-90deg)scale(.5);
  }

  50% {
    transform: translateX(42px)translateY(42px)rotate(-179deg);
  }

  50.1% {
    transform: translateX(42px)translateY(42px)rotate(-180deg);
  }

  75% {
    transform: translateX(0)translateY(42px)rotate(-270deg)scale(.5);
  }

  100% {
    transform: rotate(-360deg);
  }
}

/*# sourceMappingURL=index.3fb34588.css.map */
